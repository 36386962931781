.toggle {
    position: relative;
    display: inline-flex;
    width: 60px;
    height: 32px;

    & input {
        width: 0;
        height: 0;
    }

    & input:checked + .slider {
        background-color: #0178ab;
    }

    & input:checked + .slider:before {
        -webkit-transform: translateX(25px);
        -ms-transform: translateX(25px);
        transform: translateX(25px);
    }
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: #b2c3c8;
    transition: 0.4s;
    &::before {
        position: absolute;
        content: "";
        height: 25px;
        width: 25px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: 0.4s;
    }
    &.rounded {
        border-radius: 32px;
        &::before {
            border-radius: 50%;
        }
    }
    &.disabled {
        opacity: 0.3 !important;
        cursor: default !important;
    }
}

.toggleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.toggleLabel {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
}
