@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;400;500;600;700;800&display=swap");

$public-sans: "Public Sans", sans-serif;

@mixin public-sans-font($font-weight) {
    font-family: $public-sans;
    font-weight: $font-weight;
}

.public-font-thin {
    @include public-sans-font(100);
}

.public-font-regular {
    @include public-sans-font(400);
}

.public-font-medium {
    @include public-sans-font(500);
}

.public-font-semi-bold {
    @include public-sans-font(600);
}

.public-font-bold {
    @include public-sans-font(700);
}

.public-font-extra-bold {
    @include public-sans-font(800);
}
