@import "../../../styles/base";

.table {
    border-collapse: unset;
    border-spacing: 7px;
    width: 660px;

    & td {
        border-width: 1px;
        font-family: $public-sans;
        font-size: 16px;
        overflow: hidden;
        padding: 4px 5px;
        word-break: normal;
        color: $gray;
    }

    & th {
        font-family: $public-sans;
        font-size: 16px;
        font-weight: 700;
        padding: 10px 16px 9px 16px;
        word-break: normal;
        border-radius: 4px;
    }

    &.thAddCost {
        background-color: #f5f7f8;
        text-align: left;
        width: 250px;
    }
}

.addOnCost {
    text-align: center;
}

.positiveAmount {
    color: #6ba342;
}

.negativeAmount {
    color: #d62e4f;
}

.zeroAmount {
    margin-left: 10px;
}

table > tbody > tr > td > hr {
    border: 1px solid #f2f2f2;
    width: 200px;
    display: inline-block;
    margin: 0;
}

.detailFeeItem {
    height: 2px;
    width: 10px;
    background-color: #f2f2f2;
    display: inline-block;
    margin-left: 30px;
    margin-right: 6px;
}

.tdTableAligned {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.tableContainer {
    padding: 10px 30px 10px 30px;
}

.buttonsSection {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 446px;
}

.gridContainerTitle {
    border-top: 1px solid #ccd6db;
    border-bottom: 1px solid #ccd6db;
    background-color: #e5ebed;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    gap: 10px;
    font-weight: bold;
    font-family: $public-sans;
    margin-bottom: 10px;
}

.gridContainerTitle > .gridItem {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    color: #2c3439;
    font-family: $public-sans;
}

.gridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    gap: 10px;
    margin-bottom: 10px;

    .gridItem {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
        font-family: $public-sans;
    }

    .gridItemLeft {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        text-align: left;
        font-family: $public-sans;
    }

    .gridItemGray {
        background-color: #f5f7f8;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
        font-family: $public-sans;
        border-radius: 5px;
        margin-left: 16px;
    }

    .gridItemGrayBold {
        background-color: #f5f7f8;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
        font-weight: bold;
        color: #2c3439;
        font-family: $public-sans;
        border-radius: 5px;
        margin-left: 16px;
    }

    .gridItemGrayBoldItalic {
        background-color: #f5f7f8;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
        font-weight: bold;
        color: #2c3439;
        font-style: italic;
        font-family: $public-sans;
        border-radius: 5px;
    }

    .gridItemBoldItalic {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        text-align: left;
        font-weight: bold;
        color: #2c3439;
        font-style: italic;
        font-family: $public-sans;
    }

    .gridItemBold {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        text-align: left;
        font-weight: bold;
        color: #2c3439;
        font-family: $public-sans;
    }
}

.totalContainer {
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.totalButtons {
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.totalSubtitle {
    margin-bottom: 10px;
    font-family: $public-sans;
}

.totalButtons > div {
    font-family: $public-sans;
    margin-right: 20px;
}

.totalFinalContainer {
    margin-bottom: 40px;
}

.nonPreview {
    width: 100%;
    display: flex;
    justify-content: center;
}

.nonPreviewList {
    width: 96.5%;
    display: flex;
    justify-content: center;
}

.container {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px 10px 30px;
}

.addonName {
    margin-top: 10px;
    font-family: $public-sans;
}

.addonTag {
    background-color: #dbdbdb;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
    margin-left: 10px;
    font-family: $public-sans;
    color: #07384c;
    font-weight: 700;
}
