@import "../styles/font-styles";

$white: #fff;
$midnight: #07384c;
$gray: #6f7578;
$gray-light: #dbdbdb;
$blue: #0178ab;
$darker-blue: #005b8f;
$gray-disabled-hover: #f3f3f3;

.app {
    padding: 15px 0 0 20px;
    width: 1440px;
}

@mixin container() {
    background-color: #fff;
    padding: 22px;
    margin-bottom: 12px;
    border-radius: 4px;
}

.panel-container {
    @include container();
}

.panel-container-flex {
    @include container();
    display: flex;
}

.panel-container-flex-header {
    @include container();
    display: flex;
    min-height: 80px;
    height: 80px;
}

.panel-container-recommended {
    @include container();
    min-height: 430px;
}

.panel-container-addons {
    @include container();
    min-height: 170px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* Custom class for buttons */
@mixin btn($bg, $color, $border) {
    padding: 8px 24px;
    border-color: $border !important;
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    min-width: fit-content;
    cursor: pointer;
    font-family: $public-sans;
    font-size: 16px;
    color: $color !important;
    background-color: $bg !important;
}

@mixin hover-btn($bg, $color, $border) {
    &:hover {
        color: $color !important;
        border-color: $border !important;
        background-color: $bg !important;
    }
}

.btn-add {
    @include btn($blue, $white, transparent);
    width: 200px;
    &:hover {
        @include hover-btn($darker-blue, $white, transparent);
    }
}

.btn-add-right {
    @include btn($blue, $white, transparent);
    width: 200px;
    margin-left: 20px;
    &:hover {
        @include hover-btn($darker-blue, $white, transparent);
    }
}

.btn-cancel {
    @include btn($white, $blue, $blue);
    width: 130px;
    &:hover {
        @include hover-btn($gray-disabled-hover, $blue, $blue);
    }
}

.btn-already-added {
    @include btn($white, $blue, $blue);
    width: 175px;
    height: 37px;
    padding: 0;
    &:hover {
        @include hover-btn($gray-disabled-hover, $blue, $blue);
    }
}

.btn-select {
    @include btn($blue, $white, transparent);
    &:hover {
        @include hover-btn($darker-blue, $white, transparent);
    }
    margin-left: 25px;
    width: 130px;
    font-weight: 400;
}

.btn-select-disabled {
    @include btn($gray, $white, $gray);
    margin-left: 25px;
    width: 130px;
    font-weight: 400;
}

.btn-save {
    @include btn($blue, $white, transparent);
    margin-left: 25px;
    width: 200px;
    font-weight: 400;
}

.btn-saving,
.btn-saved {
    @include btn($gray, $white, $gray);
    margin-left: 25px;
    width: 200px;
    font-weight: 400;
}

.btn-delete {
    @include btn($blue, $white, transparent);
    margin-left: 25px;
    width: 130px;
    font-weight: 400;
    &:hover {
        @include hover-btn($darker-blue, $white, transparent);
    }
}

.btn-back-finances {
    @include btn($blue, $white, transparent);
    width: 130px;
    margin-right: 10px;
}

.btn-submit {
    @include btn($blue, $white, transparent);
    margin-left: 20px;
    &:hover {
        @include hover-btn($darker-blue, $white, transparent);
    }
}

/* Custom class for Badge */
@mixin badge() {
    font-family: $public-sans;
    padding: 0.35em 0.65em;
    font-size: 1em;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    border-radius: 10rem;
}

.badge-applicable-id {
    @include badge();
    background-color: $gray-light !important;
    color: $midnight !important;
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
}

/* Custom class for Alerts */

@mixin alert() {
    float: none;
    font-size: 16px;
    font-family: $public-sans;
}

.addon-alert {
    @include alert();
    width: 1402px;
}

/* Globals */

.flex {
    display: flex;
}

.flex-centered-vertically {
    display: flex;
    align-items: center;
}

.flex-centered-horizontally {
    display: flex;
    justify-content: center;
}

.flex-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-space-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.cursor-pointer {
    cursor: pointer;
}

.icon-disabled {
    cursor: not-allowed;
}

.text-center {
    text-align: center;
}

.addon-not-configured {
    padding: 4px 10px;
    background-color: #dbdbdb;
    color: $midnight;
    border-radius: 10px;
}

.button-space {
    margin-left: 20px;
}

.preview-container {
    margin-bottom: 40px;
}

.preview-subtitle-fee {
    font-family: $public-sans;
    font-weight: 700;
    font-size: 20px;
}
