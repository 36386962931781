.container {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px 10px 30px;
}

.addonName {
    margin-top: 10px;
    font-family: "Public Sans";
}

.icon {
    filter: invert(89%) sepia(99%) saturate(2%) hue-rotate(189deg) brightness(110%) contrast(97%);
    margin-left: 3px;
    margin-top: -4px;
}
