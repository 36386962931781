@import "../../styles/font-styles";

.spinner {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spinnerText {
    margin-top: 26px;
    font-size: 18px;
    font-weight: 400;
    font-family: $public-sans;
}
