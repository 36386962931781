@import "../../styles/font-styles";

.trashIcon {
    align-self: center;
    filter: invert(62%) sepia(64%) saturate(413%) hue-rotate(3deg) brightness(102%) contrast(84%);
}

.buttonsSection {
    margin-top: 30px;
}

.tripProtectionModalTitle {
    font-family: $public-sans;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.019em;
    text-align: left;
    color: #1e1e1e;
    margin-bottom: 20px;
}

.inputDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    padding-top: 40px;
    padding-bottom: 10px;
}

.inputTitle {
    font-family: $public-sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.019em;
    text-align: left;
    color: #1e1e1e;
}

.inputContainer {
    width: 40%;
    border-radius: 5px;
}

.inputText {
    width: 275px;
    height: 100%;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #e5e7eb;
    border-radius: 5px;
    background-color: #ffffff;
    transition: border-color 0.3s ease;
    outline: none;
    margin-left: 20px;
}

.inputText:focus {
    border-color: #0078ab;
}

.inputText::placeholder {
    color: #969a9c;
}

.errorMessage {
    color: #d62e4f;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 16px;
}

.inputSubtitle {
    font-family: $public-sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.011em;
    padding-left: 16px;
    text-align: left;
    color: #6f7578;
}

.tripPolicy {
    align-items: center;
    font-family: $public-sans;
    margin-top: 22px;
    margin-bottom: 12px;
}

.tripAntAlert {
    padding-block: 19px !important;
    padding-inline: 11px !important;
    margin-left: 10px;
    margin-right: 45px;
    width: 753px;
}

.tripAntCheckbox {
    margin-left: -10px !important;
}

.selectTripProtection {
    margin-left: 20px;
    width: 573px;
}

.feeRow {
    display: grid;
    grid-template-columns: 0.1fr 0.7fr 2fr 1fr;
    margin-top: 10px;
    margin-bottom: 40px !important;
    align-items: center;
    font-family: $public-sans;
}

.id {
    margin-right: 35px;
}

.name {
    margin-right: 115px;
}

.titles {
    margin-top: 25px;
    font-family: $public-sans;
}

.idValue {
    margin-right: 41px;
    margin-left: 20px;
}

.nameValue {
    margin-right: 64px;
}

.dogsInformationPanel {
    font-family: $public-sans;
    width: 475px;
    border-radius: 3px;
    margin: auto;
    padding: 8px 0;
    display: flex;
    -webkit-box-align: baseline;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    background-color: #deebff;
    color: inherit;
}

.dogsText {
    font-family: $public-sans;
    margin-left: 10px;

    & span {
        font-weight: 600;
    }
}

.inputAddon {
    height: 25px;
    width: 25px;
    border-color: #c0cfd6;
    border-radius: 4px;
    border-style: solid;
}

.textDanger {
    color: #d62e4f;
}

.hrModal {
    width: 555px;
    margin: 25px 0 0 6px;
    padding: 0;
    border: none;
    height: 1px;
    color: #ccd6db;
    background-color: #ccd6db;
}

.trashIconError {
    align-self: center;
    filter: invert(25%) sepia(83%) saturate(2197%) hue-rotate(329deg) brightness(88%) contrast(91%);
}

.fetchError {
    word-break: break-word;
}

.inputLink {
    font-family: $public-sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #0078ab;
    text-decoration: none;
    cursor: pointer;
}

.alertTripProtectionInfo {
    width: 619px;
    margin-left: 16px;
}

.listOfChannels {
    text-align: center;
    list-style-type: none;
    & a {
        color: #07384c;
        font-weight: 700;
    }
}
