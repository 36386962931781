/* Header Item */
.title {
    display: inline-block;
    width: 92px;
}

.itemValue {
    margin-left: 20px;
    & a {
        text-decoration: none;
        color: #0078ab;
    }
}

/* Header Information */
.leftPanel {
    line-height: 27px;
}

.rightPanel {
    margin-left: 210px;
    line-height: 27px;
}
