.switchAddonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px 10px 30px;
    transition: opacity 0.2s ease-out;
}

.spinnerContainer {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addonName {
    margin-right: 15px;
}

.addonNoAvailable {
    color: #d43354;
}

.hrToggle {
    width: 100%;
    margin: 10px 0px 10px 0px;
    padding: 0;
    border: none;
    height: 1px;
    color: #ccd6db;
    background-color: #ccd6db;
}
